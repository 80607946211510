import { useNavigate } from 'react-router';
import { appActions } from '../../../../../App/appSlice';
import { useAppDispatch } from '../../../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../../components/Modals/GenericModal';
import { logoutThunk } from '../../../../auth/authThunks';
import { modalsActions } from '../../../../modals/modalsSlice';
import useMailshotsModels from '../../../hooks/useMailshotsModels';
import { mailshotsActions } from '../../../mailshotsSlice';
import styles from '../../../styles/mailshotsModal.module.scss';
import MailshotDeleteModalContent from './MailshotDeleteModalContent';
import MailshotRenameForm from './MailshotRenameForm';

function MailshotActionModal() {
  const {
    actionModalDatas,
    resetActionData,
    quillDatasBeforeNavigation,
    selectedModel,
    updateModel,
    setSelectedModel,
    modelsForGenericTabs,
    dispatchMailshotsModal,
    updatingQuill,
  } = useMailshotsModels({});
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleClose = () => resetActionData();

  const actionRedirections = (isCancel: boolean = false) => {
    if (quillDatasBeforeNavigation?.modelTab) {
      setSelectedModel(quillDatasBeforeNavigation?.modelTab as MailshotModelType);

      // update model tab
      const index = modelsForGenericTabs().findIndex(
        (tab) => tab.id === quillDatasBeforeNavigation?.modelTab?.id
      );

      dispatch(mailshotsActions.setMailshotModelTabs(index));
      handleClose();
    }

    if (quillDatasBeforeNavigation?.accountTab) {
      dispatch(appActions.setAccountTab(quillDatasBeforeNavigation?.accountTab));
      handleClose();
    }
    if (quillDatasBeforeNavigation?.actionTab) {
      const at = quillDatasBeforeNavigation?.actionTab;
      dispatchMailshotsModal(at.selectedModel, at.entity, at.action, isCancel);
    }
    if (quillDatasBeforeNavigation?.tuto) {
      dispatch(appActions.setAccountFaqMailshotSlides());
    }
    if (quillDatasBeforeNavigation?.bannerTab) {
      switch (quillDatasBeforeNavigation.bannerTab) {
        case 'logout':
          dispatch(logoutThunk());
          navigate('/');
          break;
        case 'goToHome':
          navigate('/');
          break;
        case 'companyChange':
          dispatch(modalsActions.companyChange(true));
          break;
        default:
          break;
      }
      handleClose();
    }
  };
  const handleSaveCallback = () => {
    if (selectedModel && quillDatasBeforeNavigation?.content) {
      const updatedModel: MailshotModelType = {
        ...selectedModel,
        content: { ops: quillDatasBeforeNavigation.content },
      };
      updateModel(updatedModel);
    }
  };
  const handeRedirectionCallback = () => {
    if (!updatingQuill) {
      actionRedirections();
    }
  };
  const handleCancelCallback = () => {
    dispatch(mailshotsActions.setUpdatingQuill(false));
    actionRedirections(true);
  };

  if (!actionModalDatas) return null;

  const modalDisplay = () => {
    switch (actionModalDatas.type) {
      case 'confirm':
        return {
          actionType: GenericModalActionEnum.OKCANCEL,
          okCallback: handleSaveCallback,
          modalType: GenericModalEnum.INFO,
          title: 'Sauvegarder le modèle',
        };
      case 'create':
        return {
          actionType: GenericModalActionEnum.NONE,
          okCallback: handleClose,
          modalType: GenericModalEnum.INFO,
          title: 'Créer un modèle',
        };
      case 'update':
        return {
          actionType: GenericModalActionEnum.NONE,
          okCallback: handleClose,
          modalType: GenericModalEnum.INFO,
          title: 'Modifier le modèle',
        };
      case 'delete':
        return {
          actionType: GenericModalActionEnum.NONE,
          okCallback: handleClose,
          modalType: GenericModalEnum.INFO,
          title: 'Supprimer le modèle',
        };
      case 'updateSuccess':
        return {
          actionType: GenericModalActionEnum.OK,
          okCallback: quillDatasBeforeNavigation
            ? handeRedirectionCallback
            : handleClose,
          modalType: GenericModalEnum.CONFIRMATION,
          title: 'Modifier le modèle',
        };

      default:
        return {
          actionType: GenericModalActionEnum.NONE,
          okCallback: handleClose,
          modalType: GenericModalEnum.CONFIRMATION,
          title: '',
        };
    }
  };
  return (
    <GenericModal
      actionType={modalDisplay().actionType}
      modalType={modalDisplay().modalType}
      okButtonContent={actionModalDatas.type === 'confirm' ? 'Sauvegarder' : 'OK'}
      cancelButtonContent={
        actionModalDatas.type === 'confirm' ? 'Continuer' : undefined
      }
      closeModal={handleClose}
      okCallback={modalDisplay().okCallback}
      cancelCallback={
        actionModalDatas.type === 'confirm' ? handleCancelCallback : undefined
      }
      hasIcon={false}
      title={modalDisplay().title}
      className={styles.genericModalOverride}
      dataCy="mailshot-action-modal"
    >
      <div className={styles.mailshotActionModalContent}>
        {actionModalDatas.type === 'delete' && <MailshotDeleteModalContent />}

        {(actionModalDatas.type === 'create' ||
          actionModalDatas.type === 'update') && <MailshotRenameForm />}
        {actionModalDatas.type === 'updateSuccess' && (
          <div className={styles.updateContent}>
            <p data-cy="mailshot-modal-update-success-content">
              Le modèle a été modifié avec succès.
            </p>
          </div>
        )}
        {actionModalDatas.type === 'confirm' && (
          <div className={styles.updateContent} data-cy="save-modal-message">
            <p>
              Voulez vous sauvegarder vos modifications avant de quitter la page?
            </p>
          </div>
        )}
      </div>
    </GenericModal>
  );
}

export default MailshotActionModal;
