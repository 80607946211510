import FormButtonContainer from '../../../../../shared/components/buttons/formButtonContainer/FormButtonContainer';
import useMailshotsModels from '../../../hooks/useMailshotsModels';
import styles from '../../../styles/mailshotsModal.module.scss';

function MailshotDeleteModalContent() {
  const { actionModalDatas, deleteModel, resetActionData } = useMailshotsModels({});
  const handleValid = () => {
    deleteModel(actionModalDatas?.model ?? null);
  };
  return (
    <>
      <div
        className={styles.deleteContent}
        data-cy="mailshot-model-delete-modal-content"
      >
        <p>Voulez vous vraiment supprimer ce modèle?</p>
        <p>Ce choix sera définitif.</p>
      </div>
      <FormButtonContainer
        canCancel
        validContent="Supprimer"
        onValid={handleValid}
        onCancel={resetActionData}
        cancelDataCy="modal-button-cancel"
        submitDataCy="modal-button-submit"
      />
    </>
  );
}

export default MailshotDeleteModalContent;
