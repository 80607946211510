interface SortOptions<T> {
  order?: Order;
  priorityValues?: (T | T[keyof T])[];
  noPriorityValues?: (T | T[keyof T])[];
  key?: keyof T;
}

/**
 * Sorts an array of strings, numbers, dates, or objects.
 * with ossibility t add prio and no prio values
 *
 * @template T - The type of the elements in the array.
 * @param {T[]|undefined} array - The array to sort.
 * @param {SortOptions<T>} [options={}] - The sorting options.
 * @param {SortOrder} [options.order='asc'] - The sorting order ('asc' for ascending, 'desc' for descending).
 * @param {(T | T[keyof T])[]} [options.priorityValues=[]] - An array of values that should appear first in the ranking.
 * @param {(T | T[keyof T])[]} [options.nonPriorityValues=[]] - An array of values that should appear last in the ranking.
 * @param {keyof T | string} [options.key] - The key to use for sorting objects.
 * @returns {T[]} - The sorted array.
 */
export function genericEntitySort<T>(
  tab?: T[] | undefined,
  options: SortOptions<T> = {}
): T[] | undefined {
  if (tab === undefined) return undefined;
  const { order = 'asc', priorityValues, noPriorityValues, key } = options;

  const compare = (a: T, b: T): number => {
    let aValue: any;
    let bValue: any;

    // if sortValues object existe, use it for sort, else use base object
    if (
      typeof key === 'string' &&
      (a as ISortable).sortValues &&
      (b as ISortable).sortValues
    ) {
      aValue = (a as ISortable).sortValues![key] ?? null;
      bValue = (b as ISortable).sortValues![key] ?? null;
    } else {
      aValue = key ? a[key] : a;
      bValue = key ? b[key] : b;
    }

    // Date compare if values are instanceof Date
    if (aValue instanceof Date && bValue instanceof Date) {
      aValue = aValue.getTime();
      bValue = bValue.getTime();
    }
    // case of values to be at start of sort
    if (priorityValues) {
      if (priorityValues.includes(aValue) && priorityValues.includes(bValue)) {
        return priorityValues.indexOf(aValue) - priorityValues.indexOf(bValue);
      } else if (priorityValues.includes(aValue)) {
        return -1;
      } else if (priorityValues.includes(bValue)) {
        return 1;
      }
    }
    // case of values to be at the end of sort
    if (noPriorityValues) {
      if (noPriorityValues.includes(aValue) && noPriorityValues.includes(bValue)) {
        return noPriorityValues.indexOf(aValue) - noPriorityValues.indexOf(bValue);
      } else if (noPriorityValues.includes(aValue)) {
        return 1;
      } else if (noPriorityValues.includes(bValue)) {
        return -1;
      }
    }

    // set to lowerCase and no accent on letters for sort
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'asc'
        ? aValue.localeCompare(bValue, undefined, { sensitivity: 'base' })
        : bValue.localeCompare(aValue, undefined, { sensitivity: 'base' });
    }

    // if one value is null or undefined. put it at the end of sort
    if (aValue === null || aValue === undefined) return 1;
    if (bValue === null || bValue === undefined) return -1;

    // final classic sort
    // if (bValue === aValue) return 0;
    if (aValue < bValue) return order === 'asc' ? -1 : 1;
    if (aValue > bValue) return order === 'asc' ? 1 : -1;
    return 0;
  };

  // spread the array because sort function returns the same array
  // (same ref) and redux don't like that
  return [...tab].sort(compare);
}
