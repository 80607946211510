import { useMap, useMapEvents } from 'react-leaflet';
import { useLocation } from 'react-router';
import useRightPanel from '../../App/hooks/useRightPanel';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { autocompleteActions } from '../../features/autocomplete/autocompleteSlice';
import { getCompanyState } from '../../features/company/companySlice';
import { getExternalPdfErrialState } from '../../features/externalPdf/externalPdfSlice';
import { fetchPlotForMultiPlotPdfThunk } from '../../features/externalPdf/services/thunks/fetchPlotForMultiPlotPdfThunk';
import { getMapPluginsUrbaToolbarState } from '../../features/map/leaflet/plugins/mapPluginSlice';
import { getMapState, mapActions } from '../../features/map/mapSlice';
import { modalsActions } from '../../features/modals/modalsSlice';
import { getPanelState } from '../../features/panels/panelsSlice';
import { getMultiPlotSelectState } from '../../features/plot/multiPlotSelectFeature/multiPlotSelectSlice';
import { fetchPlotForMultiPlotThunk } from '../../features/plot/multiPlotSelectFeature/multiPlotSelectThunk';
import { plotServitudesActions } from '../../features/plotServitudes/plotServitudesSlice';
import fetchPlotRequestBuildParams from '../../lib/fetchPlotRequestBuildParams';
import plotActions from '../../redux/plot/actions';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { PDF_URLS } from '../../shared/constants';
import { addrServProcessAsync } from '../../utils/addrServProcessAsync';
import { GenericModalEnum } from '../Modals/GenericModal';

const EventLayer = () => {
  const dispatch = useAppDispatch();
  const { rightPanelDisplay } = useAppSelector(getPanelState);
  const { companyId } = useAppSelector(getCompanyState);
  const { isMultiPlotSelector } = useAppSelector(getMultiPlotSelectState);
  const { plotCountPdf, isMultiPlotSelectorPdf, multiPlotsPdf } = useAppSelector(
    getExternalPdfErrialState
  );
  const { geolocDatas } = useAppSelector(getMapState);
  const { activeToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const { closeRightPanel } = useRightPanel();
  const map = useMap();
  const location = useLocation();
  const path = location.pathname;
  const pdfPath = PDF_URLS.includes(path);

  const noPlotAvailable = () => {
    map.getContainer().classList.remove('loading-plot');
    dispatch(
      modalsActions.alert({
        status: true,
        context: 'noPlotFounded',
        modalType: GenericModalEnum.INFO,
      })
    );
  };

  const newGeocodeResponseParser = (data: GeolocPointInfo | null) => {
    try {
      if (!data) {
        throw new Error('no-fr');
      }

      if (!isMultiPlotSelector && !isMultiPlotSelectorPdf) {
        dispatch(plotServitudesActions.reset());
        rightPanelDisplay && closeRightPanel();
        dispatch(autocompleteActions.setInputValue(data.address));
        const request = fetchPlotRequestBuildParams(data, null, map.getZoom());

        if (data?.inseeCode) {
          if (geolocDatas?.inseeCode && data?.inseeCode !== geolocDatas?.inseeCode) {
            dispatch({ type: 'app/changeInsee' });
          }
          dispatch(mapActions.geolocSet(data));
        }

        if (request) {
          dispatch(plotActions.fetchPlot.request(request));
        } else {
          throw new Error('no-fr');
        }
      } else {
        const coords = data.coordinates as number[];
        if (pdfPath) {
          dispatch(
            fetchPlotForMultiPlotPdfThunk({
              lat: coords[0].toString(),
              lng: coords[1].toString(),
              geolocPointInfo: data,
            })
          );
        } else {
          dispatch(
            fetchPlotForMultiPlotThunk({
              lat: coords[0],
              lng: coords[1],
              department: data.department as string,
              companyId: companyId as number,
              cityName: data.city as string,
              mapAddress: data.address ?? null,
              zipCode: data.postalCode as string,
            })
          );
        }
      }
    } catch (error: any) {
      switch (error.message) {
        case 'no-fr':
        default:
          noPlotAvailable();
          break;
      }
    }
  };

  const clickHandler = async (lat: number, lng: number) => {
    try {
      map.getContainer().classList.add('loading-plot');
      const addr = await addrServProcessAsync(lat, lng);

      newGeocodeResponseParser(addr);
    } catch (error: any) {
      switch (error?.status) {
        case 403:
          dispatch(
            modalsActions.alert({
              status: true,
              context: 'notIncludedInSubscription',
              modalType: GenericModalEnum.INFO,
            })
          );
          break;
        default:
          noPlotAvailable();
          break;
      }
    }
  };

  useMapEvents({
    async click(e) {
      if (
        (isMultiPlotSelector && path === '/') ||
        (isMultiPlotSelectorPdf &&
          pdfPath &&
          plotCountPdf < 5 &&
          multiPlotsPdf.apiStatus !== APIStatus.PENDING)
      ) {
        clickHandler(e.latlng.lat, e.latlng.lng);
      }
    },
    async dblclick(e) {
      if (!isMultiPlotSelector && !activeToolbar && !isMultiPlotSelectorPdf) {
        clickHandler(e.latlng.lat, e.latlng.lng);
      }
    },
  });

  return null;
};

export default EventLayer;
