import { postEntity } from '../../../services/axiosFiles/genericCrud';
import { contactApiToStoreParser, contactStoreToApiParser } from '../contactParser';

async function createContact(body: StudyContactForm, companyIdIri: string | null) {
  try {
    // check params
    if (!companyIdIri) throw new Error('No company Id found');
    if (!body) throw new Error('No body found');

    // parse front data properties for api request
    const parseForApi = contactStoreToApiParser(body);

    // post request
    const result = await postEntity({
      endpoint: '/contacts',
      body: { ...parseForApi, company: companyIdIri },
    });

    // parse
    const parsedResponse = contactApiToStoreParser(result);

    // return created contact
    return parsedResponse;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default createContact;
