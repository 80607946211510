export default function fetchPlotRequestBuildParams(
  data: GeolocPointInfo | null,
  studyParams: StudyParamsState | null,
  zoom?: number
) {
  if (data?.department && data?.coordinates) {
    const coords = data.coordinates as number[];
    const request: IFetchPlotRequest = {
      data: {
        department: data.department,
        lat: coords[0],
        lon: coords[1],
        zoom,
      },
      plotLocation: data,
      studyParams,
      // isMultiPlotSelector,
    };

    return request;
  } else {
    return null;
  }
}
