import useColorWrapper from '../../colorWrapper/useColorWrapper';
import GenericTooltip from '../../tooltips/GenericTooltip';
import styles from './buttonGeneric.module.scss';

export interface IGenericButtonProps {
  content: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  bgColor?: ColorType;
  color?: ColorType;
  containerClassName?: string;
  className?: string;
  tooltipClassName?: string;
  disabled?: boolean;
  tooltipText?: React.ReactNode;
  onClick?: () => void;
  dataCy?: string;
}

function ButtonGeneric({
  content,
  type = 'button',
  bgColor = 'PRIMARY',
  color = 'WHITE',
  containerClassName,
  tooltipClassName,
  className,
  disabled,
  tooltipText,
  onClick,
  dataCy,
}: IGenericButtonProps) {
  const customClasses = useColorWrapper({
    colorWrapperType: 'button',
    bgColor,
    color,
    className,
  });

  return (
    <div className={[styles.buttonGeneric, containerClassName].join(' ')}>
      <GenericTooltip
        bgColor={bgColor}
        color={color}
        className={tooltipClassName}
        text={tooltipText && <div className={styles.tooltip}>{tooltipText}</div>}
      >
        <button
          type={type}
          disabled={disabled}
          onClick={onClick}
          className={customClasses}
          data-cy={dataCy}
        >
          {content}
        </button>
      </GenericTooltip>
    </div>
  );
}

export default ButtonGeneric;
