import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useState } from 'react';
import PaginationCustom from '../../../components/Common/PaginationCustom/PaginationCustom';
import TableRowFullColumn from '../../../components/Common/TableRowFullColumn';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { genericEntitySort } from '../../../shared/utils/sortTools';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import UserContainer from './UserContainer';
import { reportingHeaderCell } from './data';

interface IReportingAllTableContainerProps {
  usersStats: IApiState<ReportingUsersStats | null>;
  sortKey: string;
  order: Order;
}

function ReportingAllTableContainer({
  usersStats,
  sortKey,
  order,
}: IReportingAllTableContainerProps): JSX.Element {
  const [indexPage, setIndexPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const hasPagination = (usersStats.result?.length ?? 0) > itemsPerPage;

  const handleChangeIndex = (index: number): void => {
    setIndexPage(index);
  };

  const handleItemsPerPageChange = (e: ChangeEventCustom) => {
    setItemsPerPage(e.target.value);
  };

  if (usersStats.apiStatus === APIStatus.PENDING) {
    return (
      <div className="reporting-loader">
        <IndividualCircularLoader size={75} />
        <p>Calcul des statistiques en cours</p>
      </div>
    );
  }

  if (usersStats.apiStatus === APIStatus.REJECTED) {
    return (
      <div className="reporting-loader">
        <p>Une erreur c&#0039;est produite lors du chargement des données</p>
      </div>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {reportingHeaderCell.map((elt) => (
              <TableCell key={nanoid()}>{elt.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <UserContainer
            displayedUsers={
              genericEntitySort<ReportingUserStats>(usersStats.result ?? [], {
                key: sortKey as keyof ReportingUserStats,
                order,
              }) ?? []
            }
            indexPage={indexPage}
            itemsPerPage={itemsPerPage}
          />
          {hasPagination && (
            <TableRowFullColumn colSpan={6}>
              <PaginationCustom
                itemPerPageLabel="Membres par page"
                elementsQty={usersStats.result?.length ?? 0}
                itemsPerPage={itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                onMinIndexTab={handleChangeIndex}
              />
            </TableRowFullColumn>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ReportingAllTableContainer;
