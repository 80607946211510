export type FromButtonType = 'create' | 'update' | 'delete' | 'end';
import ButtonCancel from '../ButtonCancel';
import ButtonGeneric from '../buttonGeneric/ButtonGeneric';
import styles from './formButtonContainer.module.scss';

export interface IFormButtonContainerProps {
  validContent?: string;
  cancelContent?: string;
  onValid?: () => void;
  onCancel?: () => void;
  canCancel?: boolean;
  disableValid?: boolean;
  disabledValidTooltipText?: React.ReactNode;
  className?: string;
  cancelDataCy?: string;
  submitDataCy?: string;
}

function FormButtonContainer({
  validContent,
  cancelContent,
  onValid,
  onCancel,
  canCancel,
  disableValid,
  className,
  disabledValidTooltipText,
  cancelDataCy,
  submitDataCy,
}: IFormButtonContainerProps) {
  return (
    <div className={[styles.formButtonContainer, className].join(' ')}>
      {canCancel && (
        <ButtonCancel
          onClick={onCancel}
          content={cancelContent ?? 'Annuler'}
          containerClassName={styles.button}
          dataCy={cancelDataCy}
        />
      )}
      <ButtonGeneric
        type="submit"
        className={styles.btnValid}
        onClick={onValid}
        disabled={disableValid}
        content={validContent ?? 'Valider'}
        tooltipText={disabledValidTooltipText}
        containerClassName={styles.button}
        dataCy={submitDataCy}
      />
    </div>
  );
}

export default FormButtonContainer;
