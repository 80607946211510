import { memo, useEffect } from 'react';
import { appActions } from '../../../App/appSlice';
import { useAppDispatch } from '../../../App/store';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import GenericTabContainer from '../../../shared/components/genericTab/GenericTabContainer';
import { MAILSHOT_MAX_MODEL_AUTHORIZED } from '../../../shared/constants';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import useMailshotsModels from '../hooks/useMailshotsModels';
import { mailshotsActions } from '../mailshotsSlice';
import styles from '../styles/mailshotAccountTabContent.module.scss';
import MailshotActionModal from './modals/mailshotActionModal/MailshotActionModal';
import QuillJsContainer from './quill/QuillJsContainer';

function MailshotAccountTabs() {
  const {
    models,
    modelsApiStatus,
    dispatchMailshotsModal,
    selectedModel,
    setSelectedModel,
    modelsForGenericTabs,
    updatingQuill,
    mailshotModelTabIndex,
  } = useMailshotsModels({
    load: true,
  });
  const dispatch = useAppDispatch();
  useEffect(() => {
    // reset mailshot store if component is destroyed
    return () => {
      dispatch(mailshotsActions.resetWithoutModels());
    };
  }, []);

  useEffect(() => {
    if (selectedModel === null && models && models.length > 0) {
      setSelectedModel(models[0]);
    }
  }, [selectedModel, models]);

  const handleTabClic = (entity: IGenericTabsLabelType) => {
    //TODO = ask before change if content is updated
    if (updatingQuill) {
      dispatch(
        mailshotsActions.setQuillDatasBeforeNavigation({
          modelTab: entity,
          content: null,
        })
      );
    } else {
      setSelectedModel((entity as MailshotModelType) ?? null);
    }
  };

  const handleAddTab = () => dispatchMailshotsModal(null, null, 'create');

  const handleUpdate = (entity: IGenericTabsLabelType) =>
    dispatchMailshotsModal(selectedModel, entity, 'update');

  const handleDelete = (entity: IGenericTabsLabelType) =>
    dispatchMailshotsModal(selectedModel, entity, 'delete');

  const handleExternalDispatch = (index: number) =>
    dispatch(mailshotsActions.setMailshotModelTabs(index));

  const handleRedirectionTutoMailshot = () =>
    updatingQuill
      ? dispatch(
          mailshotsActions.setQuillDatasBeforeNavigation({
            tuto: true,
            content: null,
          })
        )
      : dispatch(appActions.setAccountFaqMailshotSlides());

  return (
    <div className={styles.mailshotsAccountTabContent}>
      <div className={styles.mailshotsAccountTabNew}>
        <span className={styles.new}>Nouveau!</span>
        <p>Découvrez le fonctionnement du publipostage avec nos vidéos tuto:</p>
        <span
          className={styles.mailshotsAccountTabNewLink}
          onClick={handleRedirectionTutoMailshot}
          data-cy="mailshot-tuto-1"
        >
          vidéo création modèle courrier
        </span>
        <span
          className={styles.mailshotsAccountTabNewLink}
          onClick={handleRedirectionTutoMailshot}
          data-cy="mailshot-tuto-2"
        >
          vidéo génération des courriers
        </span>
      </div>

      {modelsApiStatus === APIStatus.PENDING ? (
        <div className={styles.loaderContainer}>
          <p className={styles.modelsLoaderMessage}>Chargement des modèles</p>
          <IndividualCircularLoader size={150} />
        </div>
      ) : (
        <>
          <GenericTabContainer
            isNavigationLocked={updatingQuill}
            tabs={modelsForGenericTabs() ?? []}
            selectedDefault={selectedModel ?? null}
            onSelect={handleTabClic}
            canAddTab={(models?.length ?? 0) < MAILSHOT_MAX_MODEL_AUTHORIZED}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
            onAddTab={handleAddTab}
            externalIndex={mailshotModelTabIndex}
            externalDispatch={handleExternalDispatch}
            dataCyBase="mailshot-account-tab"
          />

          <QuillJsContainer model={selectedModel} />
          <MailshotActionModal />
        </>
      )}
    </div>
  );
}

export default memo(MailshotAccountTabs);
