import { dateFormat } from '../../lib/formats/dataFormat';
import { mapObjectParser } from '../../services/common/parserCommon';
import { createDateFromString } from '../../shared/utils/dateFunctions';
import { genericEntitySort } from '../../shared/utils/sortTools';
import { isRecentCalc } from './buildPermitsOnMap/utils';

const buildPermitParser = (data: any): BuildPermit => {
  const NR = 'Non renseigné';

  const isNone = (value: string) => (value !== NR ? value : null);

  const dateFrenchParser = (date: string): string => {
    if (date === NR) {
      return date;
    } else {
      return dateFormat(date);
    }
  };

  return {
    fullPlotId: isNone(data.parcel_id),
    plotId: isNone(data.parcel_id.substring(5)),
    postalAddress: isNone(data.adress),
    autorisationType: isNone(data.autorization_type),
    autorisationNumero: isNone(data.autorization_number),
    autorisationDate: isNone(dateFrenchParser(data.effective_autorization_date)),
    autorisationDateSort: isNone(data.effective_autorization_date),
    state: isNone(data.state),
    workAchievmentDate: isNone(dateFrenchParser(data.actual_date_of_completion)),
    applicant:
      data.applicant_denomination === NR
        ? 'Particulier'
        : data.applicant_denomination,
    applicantSiren: isNone(
      data.applicant_siren_number === 'Particulier'
        ? '-'
        : data.applicant_siren_number
    ),
    typology: isNone(data.typology),
    surface: data.area,
    totalLots:
      data.housings_total === 'Non renseigné'
        ? -1
        : parseInt(data.housings_total, 10),
    totalSocialLots:
      data.social_housings_total === 'Non renseigné'
        ? -1
        : parseInt(data.housings_total, 10),
    levels: data.number_level_max,
    lng: parseFloat(data.longitude),
    lat: parseFloat(data.latitude),
  };
};

export const buildPermitsParser = (data: any): BuildPermits => {
  try {
    const parsedData = mapObjectParser<BuildPermit>(data, buildPermitParser);

    return parsedData.filter((f) => f.plotId && f.plotId !== 'enseigné');
  } catch (error) {
    return [];
  }
};

export const buildPermitsForMapParser = (
  bp: BuildPermits,
  displayedTypes: string[],
  displayedFilters: string[],
  dateFilter: DateFilterType,
  housingFilter: HousingFilterType
): BuildPermitsForMap => {
  const parsedItems: BuildPermitsForMap = [];
  const buildItems: BuildPermits =
    genericEntitySort([...bp], {
      order: 'asc',
      key: 'plotId',
    }) ?? [];
  let temp: any = {};

  try {
    for (let i = 0; i < buildItems.length; i += 1) {
      const current = buildItems[i];

      if (i === 0 || buildItems[i - 1].plotId !== current.plotId) {
        if (i > 0 && buildItems[i - 1].plotId !== current.plotId) {
          temp.content = genericEntitySort(temp.content, {
            order: 'desc',
            key: 'autorisationDateSort',
          });
          temp.tabs = temp.content.map((m: BuildPermit) => {
            return m.autorisationDate;
          });

          temp.mostRecentDate = temp.content[0].autorisationDateSort;
          temp.isRecent = isRecentCalc(temp.mostRecentDate);
          temp.state = temp.content[0].state;
          if (!(isNaN(temp.lat) || isNaN(temp.lng))) {
            parsedItems.push({ ...temp } as BuildPermitForMap);
            temp = {};
          }
        }

        temp.plotId = current.plotId;
        temp.tabs = [];
        temp.lat = current.lat;
        temp.lng = current.lng;
        temp.content = [current];
      } else {
        // if preview plotId === current plotId, add data to temp
        temp.lat = isNaN(temp.lat) && !isNaN(current.lat) ? current.lat : temp.lat;
        temp.lng = isNaN(temp.lng) && !isNaN(current.lng) ? current.lng : temp.lng;
        temp.content.push(current);
      }
    }
  } catch (error) {
    console.log(error);
  }
  let filteredItems = parsedItems;
  filteredItems = parsedItems.filter((ff) =>
    displayedTypes.includes(ff.content[0].autorisationType ?? '')
  );

  if (displayedFilters.includes('cancelled')) {
    filteredItems = filteredItems.filter((f) => f.state === 'Annulé');
  }

  //filter on permits < 3 months
  if (displayedFilters.includes('recent')) {
    filteredItems = filteredItems.filter((f) => f.isRecent);
  }

  //date filter
  if (dateFilter.start || dateFilter.end) {
    const start = createDateFromString(dateFilter.start);
    const end = createDateFromString(dateFilter.end);
    const mrDate = (value?: string | null) => createDateFromString(value ?? null);
    if (dateFilter.start && dateFilter.end) {
      filteredItems = filteredItems.filter((f) => {
        const rd = createDateFromString(f.mostRecentDate ?? null);
        return rd && start && end ? rd >= start && rd <= end : false;
      });
    } else {
      if (start) {
        filteredItems = filteredItems.filter((f) => {
          const rd = createDateFromString(f.mostRecentDate ?? null);
          return rd ? rd >= start : false;
        });
      }
      if (end) {
        filteredItems = filteredItems.filter((f) => {
          const rd = createDateFromString(f.mostRecentDate ?? null);
          return rd ? rd <= end : false;
        });
      }
    }
  }
  //housing filter
  if (housingFilter.mini || housingFilter.maxi) {
    if (housingFilter.mini && housingFilter.maxi) {
      //if mini and maxi
      filteredItems = filteredItems.filter(
        (f) =>
          f.content[0].totalLots >= (housingFilter.mini as number) &&
          f.content[0].totalLots <= (housingFilter.maxi as number)
      );
    } else {
      // then only mini or maxi
      if (housingFilter.mini) {
        filteredItems = filteredItems.filter(
          (f) => f.content[0].totalLots >= (housingFilter.mini as number)
        );
      }

      if (housingFilter.maxi) {
        filteredItems = filteredItems.filter(
          (f) => f.content[0].totalLots <= (housingFilter.maxi as number)
        );
      }
    }
  }

  return filteredItems;
};
