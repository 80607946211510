import { nanoid } from '@reduxjs/toolkit';
import { useContext } from 'react';
import { GenericPopupContext } from './DpeMarkerPopupContainer';
import DpePopupContent from './DpePopupContent';

interface IDpePopupContentsProps {
  popupContents: DPES;
}
function DpePopupContents({ popupContents }: IDpePopupContentsProps) {
  const { state } = useContext(GenericPopupContext);

  return (
    <>
      {popupContents
        .filter((f, i) => state === i)
        .map((m) => (
          <DpePopupContent content={m} key={nanoid()} />
        ))}
    </>
  );
}

export default DpePopupContents;
