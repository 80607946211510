import { genericEntitySort } from '../../shared/utils/sortTools';

export const transactionToDvfPopupParser = (
  transactions: ITransaction[]
): IDvfPopup[] => {
  const distinctParcelIds = transactions
    .map((m) => m.parcelleId)
    .filter((t, i, a) => a.indexOf(t) === i);

  const formatedDatas = distinctParcelIds.reduce((acc: IDvfPopup[], cur: string) => {
    const selected = transactions.filter((f) => f.parcelleId === cur);

    const lng = parseFloat(
      selected.find((s) => s.lng !== 'Non renseigné')?.lng || ''
    );
    const lat = parseFloat(
      selected.find((s) => s.lat !== 'Non renseigné')?.lat || ''
    );

    const sortedTransactions = genericEntitySort(
      selected.map((s) => ({
        mutationDate: s.mutationDate,
        landValue: s.landValue,
        localType: s.localType,
        area: s.area,
        sqmPrice: s.sqmPrice,
        room: s.cntRoom,
      })),
      { order: 'desc', key: 'mutationDate' }
    );

    if (lng && lat) {
      return acc.concat({
        parcelleId: cur,
        lat,
        lng,
        transactions: sortedTransactions ?? [],
      });
    } else {
      return acc;
    }
  }, []);

  return formatedDatas;
};
