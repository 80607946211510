import { predictionTypes } from '../../shared/services/AddressServerService';
/*
FR : France + Corse
RE : Reunion
GF : Guyane Française
MQ : Martinique
GP : Guadeloupe
YT : Mayotte
*/

const domCountries = ['RE', 'GF', 'MQ', 'GP', 'YT'];
const parseCountry = () => {
  const parsed = domCountries.map((m) => 'country:' + m + '|').join('');

  return parsed.substring(0, parsed.length - 1);
};

export const addrServPredictionSearchType = {
  MUNICIPALITY: 'municipality',
  NONE: '',
};

export const defaultGoogleOptions = (isFr: boolean, sessiontoken: string) => {
  return { components: isFr ? 'country:FR' : parseCountry(), sessiontoken };
};

export const defaultServerAddressOptions: ServerAddressAutocompleteOptions = {
  type: predictionTypes.NONE,
  autocomplete: 1,
  limit: 10,
};
