import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './formComponents.module.scss';
interface IRadioBtnFormProps {
  label: string;
  name: string;
  radioBtnValues: IValueDisplayType[];
  className?: string;
  disabled?: boolean;
  dataCy?: string;
}
function RadioBtnFormCustom({
  label,
  name,
  radioBtnValues,
  disabled,
  className,
  dataCy,
}: IRadioBtnFormProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControl
            className={[
              styles.radioGroupContainer,
              className,
              errors[name] ? styles.error : '',
            ].join(' ')}
            disabled={disabled}
          >
            <FormLabel id="mailshotModelTypeLabel" className={styles.groupLabel}>
              {label}
            </FormLabel>
            <RadioGroup
              value={value ?? ''}
              onChange={onChange}
              className={[styles.radioGroup, errors[name] ? styles.error : ''].join(
                ' '
              )}
              aria-labelledby="mailshotModelTypeLabel"
              row
              data-cy={dataCy}
            >
              {radioBtnValues.map((m, i) => (
                <FormControlLabel
                  className={styles.radioLabel}
                  key={nanoid()}
                  value={m.value}
                  control={<Radio data-cy={`radio-btn-${i}`} />}
                  label={m.display}
                />
              ))}
            </RadioGroup>
            {errors[name]?.message && (
              <p>{(errors[name]?.message as string) ?? ' '}</p>
            )}
          </FormControl>
        )}
      />
    </div>
  );
}

export default RadioBtnFormCustom;
