import store from '../../../App/store';
import { GenericModalEnum } from '../../../components/Modals/GenericModal';
import { fetchDatas } from '../../../services/axiosFiles/genericCrud';
import { MAILSHOT_DEFAULT_MODELS } from '../../../shared/constants';
import { genericEntitySort } from '../../../shared/utils/sortTools';
import { modalsActions } from '../../modals/modalsSlice';
import { mailshotModelsParser } from '../utils/mailshotModelParser';

async function fetchMailshotModels(companyIdIri: string | null) {
  try {
    if (!companyIdIri) throw new Error('companyIdIri missing');

    const result = await fetchDatas(`${companyIdIri}/direct_mail_templates`);

    const parsedResult = mailshotModelsParser(result['hydra:member']);

    const sortedRsult =
      genericEntitySort(parsedResult, {
        key: 'name',
        priorityValues: MAILSHOT_DEFAULT_MODELS.map((m) => m.name),
      }) ?? [];

    return sortedRsult;
  } catch (error) {
    store.dispatch(
      modalsActions.alert({
        status: true,
        context: 'fetchMailshotModelError',
        modalType: GenericModalEnum.ERROR,
      })
    );
    return Promise.reject(error);
  }
}

export default fetchMailshotModels;
