import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { fetchProceduresThunk } from '../../../procedures/proceduresThunk';
import createStudyProcedure from '../createStudyProcedure';

const createStudyProcedureThunk = createAsyncThunk(
  'study/createStudyProcedureThunk',
  async (
    params: { datas: StudyProcedureExtended; callback: () => void },
    thunkOptions
  ) => {
    try {
      const { datas } = params;
      const state = thunkOptions.getState() as RootState;
      const { type } = state.studyParams;
      const { procedureTypes, contactRoles } = state.app;
      const { users } = state.users;

      let extendedBody: any = {
        ...params.datas,
        folder: type === 'folder' ? datas.studyIdIri : undefined,
        plotStudy: type === 'plotStudy' ? datas.studyIdIri : undefined,
        sector: type === 'sector' ? datas.studyIdIri : undefined,
      };
      delete extendedBody.studyIdIri;

      const result = await createStudyProcedure({
        body: extendedBody,
        users,
        contactRoles: contactRoles.result,
        procedureTypes: procedureTypes.result,
      });

      // fetch upcoming procedures
      thunkOptions.dispatch(fetchProceduresThunk());

      // shut down the form window
      params.callback();

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default createStudyProcedureThunk;
