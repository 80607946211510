import { TableBody, TableCell, TableRow } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../App/store';
import fetchPlotRequestBuildParams from '../../../lib/fetchPlotRequestBuildParams';
import plotActions from '../../../redux/plot/actions';
import { addrServProcessAsync } from '../../../utils/addrServProcessAsync';
import { autocompleteActions } from '../../autocomplete/autocompleteSlice';
import { mapActions } from '../../map/mapSlice';
import { plotServitudesActions } from '../../plotServitudes/plotServitudesSlice';

interface ITableBodyContentProps {
  filteredBuildPermits: BuildPermits;
  department: string | null;
}

function TableBodyContent({
  filteredBuildPermits,
}: ITableBodyContentProps): JSX.Element {
  const dispatch = useAppDispatch();
  const handlePlotIdClick = async (e: any, p: BuildPermit) => {
    if (p.lat && p.lng) {
      const addr = await addrServProcessAsync(p.lat, p.lng);
      dispatch(plotServitudesActions.reset());
      dispatch(
        autocompleteActions.setInputValue(p.postalAddress ?? addr?.address ?? '')
      );

      const request = fetchPlotRequestBuildParams(addr, null, 17);

      if (addr?.inseeCode) {
        dispatch(mapActions.geolocSet(addr));
      }

      if (request) {
        dispatch(plotActions.fetchPlot.request(request));
      } else {
        throw new Error('no-fr');
      }
    }
  };
  return (
    <TableBody>
      {filteredBuildPermits.map((p) => {
        return (
          <TableRow key={nanoid()}>
            <TableCell>{p.postalAddress}</TableCell>
            <TableCell
              onClick={(e: any) => handlePlotIdClick(e, p)}
              style={{ cursor: 'pointer', fontWeight: 700 }}
            >
              {p.plotId}
            </TableCell>
            <TableCell>{p.autorisationType}</TableCell>
            <TableCell>{p.autorisationNumero}</TableCell>
            <TableCell>{p.autorisationDate}</TableCell>
            <TableCell>{p.state}</TableCell>
            <TableCell>{p.workAchievmentDate}</TableCell>
            <TableCell>{p.applicant}</TableCell>
            <TableCell>{p.applicantSiren}</TableCell>
            <TableCell>{p.typology}</TableCell>
            <TableCell>{p.surface}</TableCell>
            <TableCell>{p.totalLots >= 0 ? p.totalLots : ' - '}</TableCell>
            <TableCell>
              {p.totalSocialLots >= 0 ? p.totalSocialLots : ' - '}
            </TableCell>
            <TableCell>{p.levels}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}

export default TableBodyContent;
